/* ---------------------------------------------
woocommerce
--------------------------------------------- */
.section-title {
  margin-bottom: 30px;
  overflow: hidden;
}

/*========================
Cart / Checkout
============================*/
.cart-collaterals .cart_totals {
  width: 100%;
  padding: 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: #F5F5F5;
}

.cart-collaterals .cart_totals h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e5e5e5;
  color: #000;
}

.cart-collaterals .cart_totals .shop_table {
  width: 100%;
}

.cart-collaterals .cart_totals .shop_table th,
.cart-collaterals .cart_totals .shop_table td {
  padding-bottom: 10px;
}

.cart-collaterals .cart_totals .wc-proceed-to-checkout {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.cart-collaterals .cart_totals .wc-proceed-to-checkout a {
  font-size: 16px;
  display: block;
  margin-bottom: 0;
  padding: 10px 20px;
  text-align: center;
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
  width: 100%;
}

table.cart .product-thumbnail img {
  max-width: 3.706325903em;
  height: auto;
  margin: 0 auto;
}

table.cart td.product-remove,
table.cart td.actions {
  border-top-color: #fff;
}

table.cart th {
  background-color: #000;
  color: #fff;
  text-transform: capitalize;
}

table.cart td a {
  color: #222;
}

table.cart td a:hover {
  color: #777;
}

.product-subtotal {
  color: #000;
  font-weight: 700;
}

.added_to_cart,
.button,
button,
input[type=button],
input[type=reset],
input[type=submit] {
  font-weight: 600;
  display: inline-block;
  padding: .6180469716em 1.41575em;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  border: 0;
  border-color: #43454b;
  border-radius: 4px;
  outline: 0;
  background: 0 0;
  background-color: #43454b;
  text-shadow: none;
}

.added_to_cart:hover,
.button:hover,
button:hover,
input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
  border-color: #000;
  background: #000;
}

table.cart td.actions .coupon {
  margin-right: 1rem;
}

.added_to_cart.disabled,
.added_to_cart:disabled,
.button.disabled,
.button:disabled,
button.disabled,
button:disabled,
input[type=button].disabled,
input[type=button]:disabled,
input[type=reset].disabled,
input[type=reset]:disabled,
input[type=submit].disabled,
input[type=submit]:disabled {
  cursor: not-allowed;
  opacity: .5 !important;
}

.woocommerce form .form-row select {
  height: 45px;
  border-radius: 0px;
  background: #F9F9F9;
  border-color: #eee;
}

.woocommerce form .form-row select:focus {
  border-color: var(--tutori-secondary-color);
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

@media (min-width: 768px) {
  table.shop_table_responsive tbody tr td,
  table.shop_table_responsive tbody tr th {
    text-align: left;
  }
}

.woocommerce .woocommerce-result-count {
  float: left;
  margin-bottom: 1rem;
}

.woocommerce .woocommerce-ordering {
  margin: 0 0 1em;
}

.screen-reader-text {
  display: none;
}

.woocommerce-grouped-product-list.group_table {
  margin-bottom: 1rem;
}

.woocommerce div.product form.cart .group_table td:first-child {
  width: 5em;
  text-align: center;
}

.woocommerce div.product form.cart .group_table td {
  padding-bottom: .5em;
  vertical-align: middle;
  border: 0;
}

.woocommerce div.product form.cart .group_table td input {
  padding: .3rem;
}

.woocommerce div.product form.cart .group_table td label {
  margin-bottom: 0;
}

.woocommerce div.product form.cart .group_table td a {
  color: #212529;
}

.woocommerce div.product form.cart .group_table td a:hover {
  text-decoration: underline;
}

.woocommerce .input-text,
.woocommerce input[type=email],
.woocommerce input[type=password],
.woocommerce input[type=search],
.woocommerce input[type=tel],
.woocommerce input[type=text],
.woocommerce input[type=url],
.woocommerce textarea {
  padding: .53em;
  height: 55px;
  padding-left: 20px;
  background: #F5F5F5;
  border: 1px solid #F5F5F5;
}

.woocommerce .input-text:focus,
.woocommerce input[type=email]:focus,
.woocommerce input[type=password]:focus,
.woocommerce input[type=search]:focus,
.woocommerce input[type=tel]:focus,
.woocommerce input[type=text]:focus,
.woocommerce input[type=url]:focus,
.woocommerce textarea:focus {
  border-color: var(--theme-primary-color);
}

.woocommerce textarea {
  height: auto !important;
}

.woocommerce div.product form.cart .variations {
  width: 100%;
  margin-bottom: 1em;
  border: 0;
}

.woocommerce div.product form.cart .variations td.label {
  padding-right: 1em;
}

.woocommerce div.product form.cart .variations td,
.woocommerce div.product form.cart .variations th {
  line-height: 2em;
  vertical-align: top;
  border: 0;
}

#order_review {
  display: inline-block;
  padding: 40px 30px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--theme-primary-color);
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
  background: #F9F9F9;
  padding: 30px;
  margin-top: 30px;
}

#order_review table.shop_table th,
#order_review table.shop_table td {
  padding: .8rem 12px;
}

#order_review table.shop_table td {
  font-size: 14px;
}

#order_review table.shop_table .order-total .woocommerce-Price-amount.amount {
  font-size: 24px;
  font-weight: bold;
  color: #222;
}

.woocommerce table.shop_table {
  border: none;
}

.woocommerce table.shop_table th {
  line-height: 1.5em;
  padding: 9px 12px;
  vertical-align: middle;
}

.woocommerce table.shop_table.woocommerce-cart-form__contents th {
  padding: 1.618em;
}

.woocommerce table.shop_table td {
  line-height: 1.5em;
  padding: 9px 12px;
  vertical-align: middle;
  border-top: 1px solid #eee;
}

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
  font-weight: 700;
  border-top: 1px solid #eee;
}

table.cart tr:first-child td.product-remove {
  border-top-width: 1px;
}

.cart-collaterals .cart_totals .shop_table th,
.cart-collaterals .cart_totals .shop_table td {
  border: none;
  color: #000;
}

.woocommerce-form-coupon .woocommerce-form-coupon input {
  background: #eeee;
  border-color: #eee;
}

/*============================
Checkout
=========================*/
.woocommerce #payment div.payment_box,
.woocommerce-page #payment div.payment_box {
  position: relative;
  border: none;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  padding: 15px;
  margin-bottom: 20px;
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
  position: absolute;
  top: -1em;
  left: 0;
  display: block;
  margin: -1em 0 0 2em;
  content: '';
  border: 1em solid rgba(0, 0, 0, 0.05);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.woocommerce #payment div.payment_box p,
.woocommerce-page #payment div.payment_box p {
  font-size: 14px;
  line-height: 1.4285em;
  margin: -2px 0 0;
  text-shadow: none;
}

.woocommerce-checkout #payment ul.payment_methods {
  border-color: #eee;
}

#payment .payment_methods > .wc_payment_method > label {
  font-size: 18px;
  margin-bottom: 1.5rem;
  padding: 0;
}

.woocommerce form.checkout_coupon {
  padding: 20px;
  text-align: left;
  border: 1px solid #e8e8e8;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message,
.woocommerce-noreviews,
p.no-comments {
  margin-bottom: .617924em;
  padding: 1em 1.618em;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  position: relative;
  width: auto;
  padding: 1em 2em 1em 2em;
  list-style: none outside;
  word-wrap: break-word;
  color: #515151;
  border: 1px solid #e8e8e8;
  border-radius: 0;
  background-color: #fff;
}

.woocommerce-info {
  border-top-color: var(--theme-primary-color);
}

.woocommerce-error {
  border-top-color: var(--theme-primary-color);
}

.woocommerce-error a,
.woocommerce-info a,
.woocommerce-message a,
.woocommerce-noreviews a,
p.no-comments a {
  color: var(--theme-primary-color);
}

.woocommerce-error a:hover,
.woocommerce-info a:hover,
.woocommerce-message a:hover,
.woocommerce-noreviews a:hover,
p.no-comments a:hover {
  color: inherit;
}

.woocommerce-form-coupon {
  display: inline-block;
  width: 55%;
}

.woocommerce-form-coupon p {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .woocommerce-form-coupon {
    display: inline-block;
    width: 100%;
  }
}

@media (max-width: 479px) {
  .woocommerce .woocommerce-form-coupon .form-row-first,
  .woocommerce-page .woocommerce-form-coupon .form-row-first,
  .woocommerce .woocommerce-form-coupon .form-row-last,
  .woocommerce-page .woocommerce-form-coupon .form-row-last {
    width: 100%;
  }
}

.woocommerce-billing-fields__field-wrapper label,
.woocommerce-billing-fields__field-wrapper .woocommerce-input-wrapper,
.woocommerce-additional-fields__field-wrapper label,
.woocommerce-additional-fields__field-wrapper .woocommerce-input-wrapper {
  width: 100%;
  color: #000;
  margin-bottom: 7px;
}

.woocommerce-additional-fields label {
  margin-bottom: 20px;
}

.woocommerce-billing-fields {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.woocommerce-billing-fields h3 {
  margin-bottom: 2rem;
}

#order_review_heading {
  margin-bottom: 20px;
}

ul.order_details {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce ul.order_details li {
  font-size: .8em;
  line-height: 1;
  float: left;
  margin-right: 2em;
  margin-left: 0;
  padding-right: 2em;
  padding-left: 0;
  list-style-type: none;
  text-transform: uppercase;
  border-right: 1px dashed #d3ced2;
}

ul.order_details li:first-child {
  padding-top: 0;
}

ul.order_details li strong {
  padding-top: .5rem;
}

.woocommerce-order-details {
  margin-bottom: 1.5rem;
  padding: 20px;
  border-radius: 4px;
  background: #f7f7f4;
}

.woocommerce-order-details .woocommerce-order-details__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.woocommerce-customer-details {
  margin-bottom: 1.5rem;
}

.woocommerce-customer-details .woocommerce-column__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.order_details a {
  color: #212529;
}

.order_details a:hover {
  color: var(--theme-primary-color);
}

.woocommerce .woocommerce-customer-details address {
  padding: 0;
  border: none;
}

.woocommerce-customer-details--phone {
  margin-bottom: 0;
}

.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
  text-align: left;
}

.woocommerce-form p {
  margin: 0 0 15px 0;
}

.woocommerce-form__label-for-checkbox {
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.lost_password a,
.woocommerce-MyAccount-content a {
  color: var(--theme-primary-color);
}

.lost_password a:hover,
.woocommerce-MyAccount-content a:hover {
  color: #333;
}

.woocommerce-form-login {
  margin-bottom: 2rem;
}

.woocommerce-MyAccount-navigation ul,
.woocommerce-MyAccount-navigation ul li {
  list-style: none;
  border-top: none;
  border-bottom: none;
}

.woocommerce-MyAccount-navigation ul {
  padding: 0;
}

.woocommerce-MyAccount-navigation ul li {
  margin-bottom: 3px;
}

.woocommerce-MyAccount-navigation ul li a {
  display: block;
  padding: 1rem;
  color: #777;
  border-radius: 3px;
}

.woocommerce-MyAccount-navigation ul li a:hover {
  background: #f5f5f5;
}

.woocommerce-MyAccount-navigation ul li.is-active a {
  color: #000;
  background: #f5f5f5;
}

.my_account_orders .button {
  color: #fff;
}

.my_account_orders .button:hover {
  color: #fff;
}

.woocommerce-Address {
  margin-bottom: 2rem;
  padding: 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}

.woocommerce-Address h3 {
  font-size: 24px;
  font-weight: bold;
}

.woocommerce-EditAccountForm p {
  margin-bottom: 1rem;
}

.woocommerce-EditAccountForm fieldset {
  margin: 3rem 0 0 0;
}

.woocommerce-EditAccountForm fieldset legend {
  font-weight: bold;
  display: inline-block;
  width: auto;
  padding-right: 5px;
}

.widget_shopping_cart_content a.remove {
  font-size: 18px;
  line-height: 26px;
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 0;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
}

.widget_shopping_cart_content .mini_cart_item-image {
  float: left;
  padding-left: 0;
}

.widget_shopping_cart_content .mini_cart_item-desc {
  padding-right: 20px;
}

.widget_shopping_cart_content .mini_cart_item-desc a {
  padding: 0;
}

.widget_shopping_cart_content .mini_cart_item-desc .woo-c_product_category a {
  color: #777;
}

.widget_shopping_cart_content .mini_cart_item-desc .woo-c_product_category a:hover {
  color: #000;
}

.widget_shopping_cart_content .quantity {
  font-size: 14px;
  display: block;
}

.widget_shopping_cart_content .mini_cart_item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.widget_shopping_cart_content .woocomerce-mini-cart__container {
  width: 100%;
}

.widget_shopping_cart_content .woocomerce-mini-cart__container .woocommerce-mini-cart__buttons {
  margin-bottom: 0;
}

.widget_shopping_cart_content .woocomerce-mini-cart__container .woocommerce-mini-cart__buttons .button.checkout {
  line-height: 1.5;
  display: block;
  width: 100%;
  margin-bottom: .5rem;
  text-align: center;
  color: #fff;
  border-color: #43454b;
  background: #43454b;
}

.widget_shopping_cart_content .woocomerce-mini-cart__container .woocommerce-mini-cart__buttons .button.checkout:hover {
  color: #fff;
  border: 1px solid var(--theme-primary-color);
  background: var(--theme-primary-color);
}

.widget_shopping_cart_content .woocomerce-mini-cart__container .woocommerce-mini-cart__buttons .button {
  line-height: 1.5;
  display: block;
  width: 100%;
  margin-bottom: .5rem;
  text-align: center;
  color: #222;
  border: 1px solid #e8e8e8;
  background: #fff;
}

.widget_shopping_cart_content .woocomerce-mini-cart__container .woocommerce-mini-cart__buttons .button:hover {
  color: #fff;
  border: 1px solid #43454b;
  background: #43454b;
}

.widget_shopping_cart_content .woocommerce-mini-cart-item:last-child {
  padding-bottom: 0 !important;
  border: none;
}

.cart-quantity-highlighter:not(:empty) {
  font-size: 10px;
  line-height: 13px;
  position: absolute;
  top: -7px;
  left: 13px;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  text-align: center;
  border: 1px solid #545454;
  border-radius: 50%;
}

.mini-cart .dropdown-toggle::after {
  border: none;
}

.mainmenu .collapse ul > li.mini-cart > a span,
.mainmenu .collapse ul > li.mini-cart:hover > a span {
  color: #242424;
}

.attr-nav {
  float: right;
}

.woocommerce a.remove {
  color: #8c8c8c !important;
}

.woocommerce a.remove:hover {
  color: var(--theme-primary-color) !important;
  background: transparent;
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 200px;
}

.woocommerce table.cart td.actions button.button {
  color: #fff;
  background-color: #222;
  border-radius: 3px !important;
}

.woocommerce table.cart td.actions button.button:hover {
  color: #fff;
  background-color: var(--theme-primary-color);
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  color: #fff;
  background-color: #000;
  width: 100%;
  transition: all .4s ease;
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
  color: #fff;
  background-color: var(--theme-primary-color);
}

@media (max-width: 576px) {
  .woocommerce div.product form.cart {
    display: inline-block;
  }
}

@media (max-width: 495px) {
  .woocommerce div.product .woocommerce-tabs ul.tabs li.active::after,
  .woocommerce div.product .woocommerce-tabs ul.tabs li.active::before {
    box-shadow: none;
  }
}

/* ---------------------------------------------

/* ---------------------------------------------
responsive
--------------------------------------------- */
@media (max-width: 768px) {
  .woocommerce ul.order_details li {
    width: 48%;
    margin: 0;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 20px;
    border: 1px dashed #d3ced2;
  }
}

@media (max-width: 767px) {
  .space-3 {
    padding: 3rem 0;
  }
  .space-adjust {
    margin-top: -3rem;
  }
}

.woocommerce .products .star-rating {
  width: 5.6em;
  margin-top: 10px;
  float: right;
}

.woocommerce .star-rating::before {
  content: "\f005\f005\f005\f005\f005";
  color: #F5A623;
  font-family: "Font Awesome 5 Free";
}

/*---------------------------------------
Form Login/register
------------------------------------*/
.woocommerce form .form-row input.input-text {
  background: #F5F5F5;
}

.woocommerce form.checkout_coupon .form-row.form-row-first {
  float: left;
  width: 60%;
  overflow: visible;
  margin-right: 10px;
}

.woocommerce form.checkout_coupon .form-row.form-row-first .input-text {
  background: #F5F5F5;
  border: 1px solid #F5F5F5;
  width: 100%;
  height: 50px;
}

.woocommerce table.cart td.actions .coupon .button {
  width: 48%;
  box-sizing: border-box;
}

.woocommerce table.cart td.actions .coupon {
  margin-bottom: 20px;
}

.woocommerce table.cart td.actions .coupon label {
  display: none;
}

.woocommerce table.cart td.actions .coupon .input-text, .woocommerce table.cart td.actions .coupon input[type="email"], .woocommerce table.cart td.actions .coupon input[type="password"], .woocommerce table.cart td.actions .coupon input[type="search"], .woocommerce table.cart td.actions .coupon input[type="tel"], .woocommerce table.cart td.actions .coupon input[type="text"], .woocommerce table.cart td.actions .coupon input[type="url"], .woocommerce table.cart td.actions .coupon textarea {
  height: 45px;
}

.woocommerce-form p {
  margin: 0 0 25px 0;
}

.woocommerce-form label {
  color: #696969;
  display: block;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 6px;
}

.product-name {
  color: #222;
}

.woocommerce-terms-and-conditions-wrapper {
  margin-bottom: 30px;
}

.woocommerce form.checkout_coupon .form-row.form-row-first {
  width: 100%;
  margin-right: 0px;
}

.woocommerce-register a {
  color: #000;
}

.woocommerce-register a:hover {
  color: var(--theme-primary-color);
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.form-header h2 {
  text-transform: capitalize;
}

.login-form, .signup-form {
  box-shadow: 0px 16px 32px 0px rgba(196, 203, 255, 0.3);
  padding: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-form .woocommerce-button, .signup-form .woocommerce-button {
  background: var(--theme-secondary-color) !important;
  color: #fff;
}

.login-form .woocommerce-button:hover, .signup-form .woocommerce-button:hover {
  background: var(--theme-primary-color) !important;
  color: #fff;
}

.woocommerce-form .woocommerce-form__label-for-checkbox {
  text-transform: capitalize;
  color: #666;
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
}

.woocommerce-form .woocommerce-form__label-for-checkbox a {
  color: #666;
}

.form-check-input {
  margin-top: 7px;
  border: 2px solid #333;
}
